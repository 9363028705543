import './reset.scss';
import './global.scss'
import './App.scss';
import GuessCharacter from './components/GuessCharacter/GuessCharacter';

function App() {
  return (
    <div className="App">
<GuessCharacter />
    </div>
  );
}

export default App;
