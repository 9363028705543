import React from 'react';
import character from '../../data/characters.json';
import './guess.scss';

const Guess = ({ randomChampionIndex, selectedChampion }) => {
    const isNameMatch = character.data[selectedChampion].name === character.data[randomChampionIndex].name;
    const isGenderMatch = character.data[selectedChampion].gender === character.data[randomChampionIndex].gender;
    const isElementMatch = character.data[selectedChampion].element === character.data[randomChampionIndex].element;
    const isRarityMatch = character.data[selectedChampion].rarity === character.data[randomChampionIndex].rarity;
    const isWeaponMatch = character.data[selectedChampion].weapon === character.data[randomChampionIndex].weapon;
    const isRegionMatch = character.data[selectedChampion].region === character.data[randomChampionIndex].region;

    return (
        <div className="guess_container">
            <div className={`guess_trait `}>
                <img src={"img/" + character.data[selectedChampion].thumbnail} alt="" />
                {/* <p>{character.data[selectedChampion].name}</p> */}
            </div>
            <div className={`guess_trait ${isGenderMatch ? 'guess_correct' : 'guess_wrong'}`}>
                <p>{character.data[selectedChampion].gender}</p>
            </div>
            <div className={`guess_trait ${isElementMatch ? 'guess_correct' : 'guess_wrong'}`}>
                <p>{character.data[selectedChampion].element}</p>
            </div>
            <div className={`guess_trait ${isRarityMatch ? 'guess_correct' : 'guess_wrong'}`}>
                <p>{character.data[selectedChampion].rarity} star</p>
            </div>
            <div className={`guess_trait ${isWeaponMatch ? 'guess_correct' : 'guess_wrong'}`}>
                <p>{character.data[selectedChampion].weapon}</p>
            </div>
            <div className={`guess_trait ${isRegionMatch ? 'guess_correct' : 'guess_wrong'} borderright`}>
                <p>{character.data[selectedChampion].region}</p>
            </div>
        </div>
    )
}

export default Guess;