import React, { useState, useEffect } from 'react';
import character from '../../data/characters.json';
import './guesscharacter.scss';
import Guess from '../Guess/Guess';

const GuessCharacter = () => {
  const [randomChampionIndex, setrandomChampionIndex] = useState(null);
  const [selectedChampionIndex, setselectedChampionIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [guessedChampionsIndex, setGuessedChampionsIndex] = useState([]);
  const [gameFinished, setGameFinished] = useState(false);
  const [resetFlag, setResetFlag] = useState(false); // Add a reset flag

  useEffect(() => {
    const championNames = Object.keys(character.data);
    const randomIndex = Math.floor(Math.random() * championNames.length);
    setrandomChampionIndex(randomIndex);
  }, [resetFlag]);

  const handleReset = () => {
    setGuessedChampionsIndex([]);
    setResetFlag(!resetFlag); // Change resetFlag to trigger useEffect
    setGameFinished(false);

  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredChampions = Object.keys(character.data)
  .filter(championName =>
    character.data[championName].name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => {
    // Check which name starts with the search term first
    const startsWithA = character.data[a].name.toLowerCase().startsWith(searchTerm.toLowerCase());
    const startsWithB = character.data[b].name.toLowerCase().startsWith(searchTerm.toLowerCase());
    
    if (startsWithA && !startsWithB) return -1; // a comes first
    if (!startsWithA && startsWithB) return 1; // b comes first
    return 0; // maintain original order otherwise
  });

  const handleChampionClick = (championName) => {
    setselectedChampionIndex(championName);
    setSearchTerm(''); // Clear searchbar when a champion is selected
    setGuessedChampionsIndex([...guessedChampionsIndex, championName]);
    if(championName === String(randomChampionIndex)) {
      console.log("you guessed it right");
      setGameFinished(true);
    } else {
      //setGuessedChampionsIndex([...guessedChampionsIndex, championName]);
    }
  };

  if (!randomChampionIndex) {
    return <div>Loading...</div>;
  }

  return (
    <div className="site_wrapper">
      <div className="background">
        <img src="img/Background_Mondstadt.jpg" alt="" />
      </div>
      <div className="background_filter"></div>
      <div className="site_margin margin_left">

      </div>
      <div className="site_margin margin_right">
        
      </div>
      <div className="site_content">
        <div className="search_wrapper">
            {gameFinished ? (
              <div className="gamefinished">
                <p>Correct!</p>
                <p>The character was: {character.data[selectedChampionIndex].name}</p>
                <button onClick={handleReset}>Reset</button>
              </div>
            ) : (
              <div className="search_content">
                <div className="search_header">
                  <p className="" >Guess a character: </p>
                  <input
                    type="text"
                    placeholder="Search character..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
                {searchTerm && filteredChampions.length > 0 && (
                  <div className="search_results">
                    <ul className="myDropDown">
                      {filteredChampions.map((championName) => (
                        <li key={championName} onClick={() => handleChampionClick(championName)}>
                          <div className="search_thumb"><img src={"img/" + character.data[championName].thumbnail} alt="" /></div>
                          <div className="search_name">{character.data[championName].name}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        <div className="guessed_characters">
        {guessedChampionsIndex.length > 0 && ( 
          <div className="trait_header">
            <div>Character</div>
            <div>Gender</div>
            <div>Element</div>
            <div>Rarity</div>
            <div>Weapon</div>
            <div className="borderright">Origin</div>
          </div> 
        )}
          
          <>
            {guessedChampionsIndex.map((champion, index) => (
              <Guess key={index} randomChampionIndex={randomChampionIndex} selectedChampion={guessedChampionsIndex[guessedChampionsIndex.length - 1 - index]} />
            ))}
          </>
        </div>
        <div className="reset_button"><button onClick={handleReset}>Reset</button></div>
      </div>


    </div>
  );
};

export default GuessCharacter;
